import { createErrorAction } from 'kat/lib/actionCreators/createErrorAction'
import { SubmitDeleteLabelAction } from './../../../actionCreators/submit/SubmitDeleteLabelAction'
import { takeGaurd } from 'kat/lib/utilities/takeGaurd'
import { put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'kat/lib/state/selectState'
import { PatchWorkspacesRequestActionDefault } from '../../../actionCreators/types/PatchWorkspacesRequestActionDefault'

const description = 'postPlansSaga'

export const submitDeleteLabelFlow = function* () {
  while (1) {
    const action: SubmitDeleteLabelAction =
      yield takeGaurd<SubmitDeleteLabelAction>('SUBMIT_DELETE_LABEL_ACTION')
    const { labelId } = action
    const state: StateReducers = yield select(selectState)
    const workspace = state.currentWorkspace
    const workspaceId = state.currentWorkspaceId || ''
    const labels = workspace && workspace.labels

    if (workspaceId && labelId) {
      const newLabelsMutable = labels?.filter(({ id }) => {
        return id !== labelId
      })

      yield put<PatchWorkspacesRequestActionDefault>({
        type: 'PATCH_WORKSPACES_REQUEST',
        description,
        requestValues: {
          items: {
            [workspaceId]: {
              data: {
                labels: newLabelsMutable,
              },
            },
          },
        },
      })
    } else {
      yield put(createErrorAction({ description }))
    }
  }
}
