export const queryNames = {
  workspaceId: 'workspace_id',
  subscriptionId: 'subscription_id',
  userId: 'user_id',
  inviteId: 'invite_id',
  workspaceName: 'workspace_name',
  refreshIdToken: 'refresh_id_token',
  email: 'email',
  secret: 'secret',
  endToEndTesting: 'end-to-end-testing',
  modalType: 'modal_type',
  modalId: 'modal_id',
  inviteWorkspaceId: 'inviteWorkspaceId',
  taskId: 'taskId',
  taskStatus: 'taskStatus',
  taskItemId: 'taskItemId',
  planId: 'planId',
  whichMain: 'whichMain',
  labelId: 'labelId',
  scrollToStatus: 'scrollToStatus',
  guestPassword: 'guestPassword',
  searchText: 'searchText',
  whenMs: 'whenMs',
  returnHome: 'returnHome',
  referrerType: 'referrerType',
  calendarSetupId: 'calendarSetupId',
  calendarEventId: 'calendarEventId',
}
