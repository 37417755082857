import { ClearMessagesAction } from './../../actionCreators/types/ClearMessagesAction'
import { DeleteMessagesSuccessAction } from './../../actionCreators/delete/DeleteMessages/types/DeleteMessagesSuccessAction'
import { DeleteMessagesRequestAction } from './../../actionCreators/delete/DeleteMessages/types/DeleteMessagesRequestAction'
import { GetMessagesSuccessAction } from './../../actionCreators/get/GetMessages/types/GetMessagesSuccessAction'
import { PostMessagesRequestAction } from './../../actionCreators/post/PostMessages/types/PostMessagesRequestAction'
import { createGenericReducer } from 'kat/lib/state/reducers/createGenericReducer'
import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'
import { MessagesReducer } from './types/MessagesReducer'
import { Message } from '../../functions/types/Message'
import { PatchMessagesRequestAction } from '../../actionCreators/patch/PatchMessages/types/PatchMessagesRequestAction'

const postRequestAction: PostMessagesRequestAction['type'] =
  'POST_MESSAGES_REQUEST_ACTION'

const patchRequestAction: PatchMessagesRequestAction['type'] =
  'PATCH_MESSAGES_REQUEST_ACTION'

const clearWorkspaceScopeAction: ClearWorkspaceScopeAction['type'] =
  'CLEAR_WORKSPACE_SCOPE'

const getMessagesSuccessAction: GetMessagesSuccessAction['type'] =
  'GET_MESSAGES_SUCCESS_ACTION'
const deleteMessagesRequestAction: DeleteMessagesRequestAction['type'] =
  'DELETE_MESSAGES_REQUEST_ACTION'
const deleteMessagesSuccessAction: DeleteMessagesSuccessAction['type'] =
  'DELETE_MESSAGES_SUCCESS_ACTION'
const clearMessagesAction: ClearMessagesAction['type'] = 'CLEAR_MESSAGES_ACTION'

export const messagesReducer = createGenericReducer<Message, MessagesReducer>({
  getSuccessAction: getMessagesSuccessAction,
  postRequestAction,
  deleteRequestAction: deleteMessagesRequestAction,
  deleteSuccessAction: deleteMessagesSuccessAction,
  clearActions: [clearWorkspaceScopeAction, clearMessagesAction],
  patchRequestAction,
  // debug: true,
})
