import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'
import { CurrentDailyItemReducer } from './types/CurrentDailyItemReducer'
import { SetCurrentDailyItemAction } from 'actionCreators/set/SetCurrentDailyItemAction'

export const currentDailyItemReducer = (
  state?: CurrentDailyItemReducer | null,
  action?: SetCurrentDailyItemAction | ClearWorkspaceScopeAction
): CurrentDailyItemReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case 'SET_CURRENT_DAILY_ITEM_ACTION': {
      const { dailyItem, fetched, dailyItemId } = action.values || {}

      return {
        ...state,
        data:
          dailyItem === null
            ? null
            : {
                ...state?.data,
                ...dailyItem,
              },
        dailyItemId: dailyItemId || undefined,
        fetched,
      }
    }

    case 'CLEAR_WORKSPACE_SCOPE': {
      return null
    }

    default:
      return state || null
  }
}
