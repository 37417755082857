import { ClearPreviousTaskItemAction } from './../../actionCreators/types/ClearPreviousTaskItemAction'
import { ToggleAction } from 'actionCreators/ToggleAction'
import { SetAiSuggestTaskItemAction } from './../../actionCreators/set/SetAiSuggestTaskItemAction'
import { SetClearCheckedTaskItemIdsAction } from './../../actionCreators/set/SetClearCheckedTaskItemIdsAction'
import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'
import { CurrentTaskReducer } from './types/CurrentTaskReducer'
import { SetCurrentTaskAction } from './../../actionCreators/set/SetCurrentTaskAction'
import { SetCheckedTaskItemIdsAction } from '../../actionCreators/set/SetCheckedTaskItemIdsAction'
import { SearchTaskItemsDoneAction } from 'actionCreators/SearchTaskItemsDoneAction'
import { SetSearchTaskItemsInputFocusAction } from 'actionCreators/SetSearchTaskItemsInputFocusAction'
import { SetNewTaskItemsAction } from 'actionCreators/set/SetNewTaskItemsAction'
import { SetDeleteTaskItemsAction } from 'actionCreators/set/SetDeleteTaskItemsAction'
import { ClearDeleteTaskItemsAction } from 'actionCreators/set/ClearDeleteTaskItemsAction'
import { SetHighlightCheckedItemsAction } from 'actionCreators/set/SetHighlightCheckedItemsAction'
import { LocationChangeAction } from 'actionCreators/types/LocationChangeAction'
import { SetReorderTaskItemIdsAction } from 'actionCreators/set/SetReorderTaskItemIdsAction'

type State = CurrentTaskReducer | null | undefined

export const currentTaskReducer = (
  state?: State,
  action?:
    | SetCurrentTaskAction
    | ClearWorkspaceScopeAction
    | SetCheckedTaskItemIdsAction
    | SetClearCheckedTaskItemIdsAction
    | SetAiSuggestTaskItemAction
    | ToggleAction
    | ClearPreviousTaskItemAction
    | SearchTaskItemsDoneAction
    | SetSearchTaskItemsInputFocusAction
    | SetNewTaskItemsAction
    | SetDeleteTaskItemsAction
    | ClearDeleteTaskItemsAction
    | SetHighlightCheckedItemsAction
    | LocationChangeAction
    | SetReorderTaskItemIdsAction
): State => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case 'SEARCH_TASK_ITEMS_DONE_ACTION': {
      if (state) {
        return {
          ...state,
          searchTaskItems: {
            ...state.searchTaskItems,
            results: action.results,
            searchText: action.searchText,
            clear: action.clear,
          },
        }
      }
      return null
    }

    case 'SET_SEARCH_TASK_ITEMS_INPUT_FOCUS_ACTION': {
      if (state) {
        return {
          ...state,
          searchTaskItems: {
            ...state.searchTaskItems,
            isInputFocused: action.isInputFocused,
          },
        }
      }
      return null
    }

    case 'SET_AI_SUGGEST_TASK_ITEM_ACTION': {
      if (state) {
        return { ...state, suggestTaskItem: action.suggestTaskItem }
      }
      return null
    }

    case 'SET_DELETE_TASK_ITEMS_ACTION': {
      if (state) {
        return { ...state, deleteTaskItemIds: action.taskItemIds }
      }
      return null
    }

    case 'SET_HIGHLIGHT_CHECKED_ITEMS_ACTION': {
      if (state) {
        return { ...state, highlightCheckedItems: action.highlightCheckedItems }
      }
      return null
    }

    case 'CLEAR_DELETE_TASK_ITEMS_ACTION': {
      if (state) {
        return { ...state, deleteTaskItemIds: [] }
      }
      return null
    }

    case 'SET_NEW_TASK_ITEMS_ACTION': {
      if (state) {
        let newlyCreatedTaskItemIds: string[] = []

        action.taskItems.forEach(({ id }) => {
          if (id) {
            newlyCreatedTaskItemIds.push(id)
          }
        })

        return { ...state, newlyCreatedTaskItemIds }
      }
      return null
    }

    case 'TOGGLE': {
      if (state) {
        if (
          action.toggles.showTaskItemAction &&
          action.toggles.showTaskItemAction.type === 'itemActions' &&
          action.toggles.showTaskItemAction.taskItemId
        ) {
          return {
            ...state,
            previousTaskItemId: action.toggles.showTaskItemAction.taskItemId,
          }
        }
        return state
      }
      return null
    }

    case 'CLEAR_PREVIOUS_TASK_ITEM_ACTION': {
      if (state) {
        return { ...state, previousTaskItemId: null }
      }
      return null
    }

    case 'SET_CURRENT_TASK_ACTION': {
      const { task, workspaceId, taskId, isNotAvailable } = action.values || {}

      if (workspaceId && taskId) {
        return {
          ...state,
          workspaceId,
          data:
            task === null
              ? null
              : {
                  ...state?.data,
                  ...task,
                },
          taskId,
          isNotAvailable,
        }
      }

      return null
    }

    case 'SET_CLEAR_CHECKED_TASK_ITEM_IDS_ACTION': {
      if (state) {
        return {
          ...state,
          taskItemList: {
            ...state.taskItemList,
            checkedTaskItemIds: [],
          },
        }
      }

      return null
    }

    case 'SET_REORDER_TASK_ITEM_IDS_ACTION': {
      if (state) {
        return {
          ...state,
          taskItemList: {
            ...state.taskItemList,
            reorderTaskItemIds: action.taskItemIds,
          },
        }
      }

      return null
    }

    case 'LOCATION_CHANGE': {
      if (action.values.query?.taskId !== state?.taskId) {
        // if (action.values.query?.taskId && action.values.query?.workspaceId) {
        //   return {
        //     workspaceId: action.values.query?.workspaceId,
        //     taskId: action.values.query?.taskId,
        //   }
        // }
        return null
      }

      return state
    }

    case 'CHECKED_TASK_ITEM_IDS_ACTION': {
      if (state) {
        let newCheckedTaskItemIdsMutable: string[] = []

        if (action.force) {
          newCheckedTaskItemIdsMutable = action.taskItemIds
        } else {
          action.taskItemIds.forEach((taskItemId) => {
            if (!state.taskItemList?.checkedTaskItemIds?.includes(taskItemId)) {
              newCheckedTaskItemIdsMutable.push(taskItemId)
            }
          })

          state.taskItemList?.checkedTaskItemIds?.forEach((taskItemId) => {
            if (!action.taskItemIds?.includes(taskItemId)) {
              newCheckedTaskItemIdsMutable.push(taskItemId)
            }
          })
        }

        return {
          ...state,
          taskItemList: {
            ...state.taskItemList,
            checkedTaskItemIds: newCheckedTaskItemIdsMutable,
          },
        }
      }

      return null
    }

    case 'CLEAR_WORKSPACE_SCOPE': {
      return null
    }

    default:
      return state || null
  }
}
