import {
  analyticsEventBase,
  AnalyticsEventBase,
  extendsAnalyticsEventBase,
} from 'kat/lib/sdks/firebase/analyticsEventBase'
import { GenericObject } from 'types/GenericObject'

// Track an event. It can be anything, but in this example, we're tracking a Sign Up event.

export type AnalyticsEvent = AnalyticsEventBase<
  | 'Like task'
  | 'Create Card'
  | 'Create Daily Log'
  | 'Create 5th card'
  | 'Create 10th card'
  | 'Create 15th card'
  | 'Create 20th card'
  | 'Too Many Tasks'
  | 'Status Suggestion'
  | 'Use summary suggestion'
  | 'Use summary suggestion edit'
  | 'Dismiss summary suggestion'
  | 'New task item'
  | 'Delete task item'
  | 'Advance mode on'
  | 'Advance mode off'
  | 'Begin Onboarding'
  | 'Begin onboarding native'
  | 'Suggest AI - suggest'
  | 'Create your first group'
  | 'Select template'
  | 'New calendar event'
  | 'New calendar setup'
  | 'Delete calendar event'
  | 'Delete calendar setup'
  | 'Get it on Google Play'
  | 'Download on the App Store'
  | 'Web Browser'
  | 'Get started: Top'
  | 'Get started: Bottom'
  | 'Update calendar item summary'
  | 'Update calendar item when'
  | 'Link to existing task'
  | 'Link to new task'
  | 'Update task status'
  | 'Update many task status'
  | 'Update task summary'
  | 'Move task item'
  | 'Reorder task item'
  | 'Reorder task'
  | 'Delete task'
  | 'Archive task'
  | 'Unarchive task'
  | 'Remove task tag'
  | 'Replace task tag'
  | 'New folder'
  | 'Rename folder'
  | 'Update task item'
  | 'Update task item emoji'
  | 'New message'
  | 'Show more messages'
  | 'Select reminder template'
  | 'Invited create task'
  | 'Download app link'
  | 'Update workspace name'
  | 'Go back'
  | 'Auto generate new task'
  | 'New ai template'
  | 'Plan a trip'
  | 'Plan a trip destination'
  | 'Plan a trip start'
  | 'Plan a trip transportation'
  | 'Plan a trip disclaimer'
  | 'Simplify task'
  | 'Simplify task days'
  | 'Simplify task summary'
  | 'Simplify task disclaimer'
  | 'Date idea'
  | 'Date idea location'
  | 'Date idea budget'
  | 'Shopping list'
  | 'Shopping list disclaimer'
  | 'Update task calendar event'
  | 'Update task calendar setup'
  | 'Add reminder - daily'
  | 'Add reminder - weekly'
  | 'Add reminder - monthly'
  | 'Add reminder - none'
  | 'Referred'
  | 'Create a goal button'
  | 'Create goals for me'
  | 'New calendar type'
  | 'New calendar when'
  | 'New calendar repeating'
  | 'Task ideas'
  | 'Task idea selection'
  | 'Upgrade link click'
  | 'Show modal'
  | 'New workspace'
  | 'New mood'
  | 'New journal'
  | 'Delete journal'
  | 'Send kindness'
  | 'Compact view'
  | 'Create general chat'
  | 'Share on X'
  | 'Share on Facebook'
  | 'Share on Reddit'
  | 'Share on LinkedIn'
>

interface Props {
  event: AnalyticsEvent
  params?: {
    templateType?: string
  } & GenericObject<string | undefined | null>
}

extendsAnalyticsEventBase(({ event, eventParams }) => {
  window.mixpanel?.track?.(event as string, eventParams)
})

export const logAnalyticsEvent = ({ event, params }: Props) => {
  analyticsEventBase({ event, eventParams: params })
}
