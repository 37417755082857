import { createGenericReducer } from 'kat/lib/state/reducers/createGenericReducer'
import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'
import { CalendarSetupsReducer } from './types/CalendarSetupsReducer'
import { GetCalendarSetupsSuccessAction } from 'actionCreators/calendarSetup/GetCalendarSetupsSuccessAction'
// import { DeleteCalendarSetupsRequestAction } from 'actionCreators/calendarSetup/DeleteCalendarSetupsRequestAction'
// import { DeleteCalendarSetupsSuccessAction } from 'actionCreators/calendarSetup/DeleteCalendarSetupsSuccessAction'
import { CalendarSetupModel } from 'functions/types/CalendarSetupModel'
import { ClearCalendarSetupsAction } from 'actionCreators/calendarSetup/ClearCalendarSetupsAction'
import { DeleteCalendarSetupsRequestAction } from 'actionCreators/calendarSetup/DeleteCalendarSetupsRequestAction'
import { DeleteCalendarSetupsSuccessAction } from 'actionCreators/calendarSetup/DeleteCalendarSetupsSuccessAction'

const deleteRequestAction: DeleteCalendarSetupsRequestAction['type'] =
  'DELETE_CALENDAR_SETUPS_REQUEST_ACTION'

const deleteSuccessAction: DeleteCalendarSetupsSuccessAction['type'] =
  'DELETE_CALENDAR_SETUPS_SUCCESS_ACTION'

const clearWorkspaceScopeAction: ClearWorkspaceScopeAction['type'] =
  'CLEAR_WORKSPACE_SCOPE'

const getCalendarSetupsSuccess: GetCalendarSetupsSuccessAction['type'] =
  'GET_CALENDAR_SETUPS_SUCCESS_ACTION'

const clearCalendarSetupsAction: ClearCalendarSetupsAction['type'] =
  'CLEAR_CALENDAR_SETUPS_ACTION'

export const calendarSetupsReducer = createGenericReducer<
  CalendarSetupModel,
  CalendarSetupsReducer
>({
  getSuccessAction: getCalendarSetupsSuccess,
  deleteRequestAction,
  deleteSuccessAction,
  clearActions: [clearWorkspaceScopeAction, clearCalendarSetupsAction],
  // extend,
  // debug: true,
})
