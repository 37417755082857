import { createLocationAction } from 'actionCreators/createLocationAction'
import { HistoryAction } from 'kat/lib/actionCreators/types/HistoryAction'
import { selectState } from './../state/reducers/selectState'
import { takeGaurd } from 'kat/lib/utilities/takeGaurd'
import { put, select } from 'redux-saga/effects'
import { GoForwardAction } from 'kat/lib/actionCreators/GoForwardAction'
import { StateReducers } from '../state/reducers/types/StateReducers'
import { getForwardLocation } from '../state/getters/getForwardLocation'

const description = 'goFowardFlow'
export const goFowardFlow = function* () {
  while (1) {
    yield takeGaurd<GoForwardAction>('GO_FORWARD_ACTION')

    const state: StateReducers = yield select(selectState)
    const { route, query, withCurrentQuery } = getForwardLocation({ state })
    const useQuery = withCurrentQuery ? state.currentLocation?.query : query

    if (route) {
      yield put<HistoryAction>(
        createLocationAction({
          description,
          method: 'push',
          query: useQuery,
          pathname: route?.pathname,
        })
      )
    }
  }
}
