//
import 'functions/routes/appRoutes'
import 'functions/routes/queryNames'
import 'kat/lib/utilities/polyfills'
import './functions/collection/collectionNames'

import Axios from 'axios'
import React from 'react'
import { initalizeSdks } from './utilities/initalizeSdks'
import { hydrate, render } from 'react-dom'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { createAppStore } from 'kat/lib/state/createAppStore'
import { Provider } from 'react-redux'
import { flows } from 'flows/flows'
import { ToggleAction } from 'actionCreators/ToggleAction'
import { WindowResizeAction } from 'kat/lib/actionCreators/types/WindowResizeAction'
import { PushNotification } from 'kat/lib/types/PushNotification'
import { rootReducer } from 'state/reducers/rootReducer'
import { reduxLogger } from 'state/reduxLogger'
import { getViewPortWidth } from 'kat/lib/utilities/getViewPortWidth'
import { getViewPortHeight } from 'kat/lib/utilities/getViewPortHeight'
import { CookieStorage } from './types/CookieStorage'
import { cookieStore } from 'kat/lib/utilities/cookieStore'
import {
  serviceWorkerRegister,
  unregister,
} from 'kat/lib/utilities/serviceWorker'
import { PRIMARY_STORAGE_KEY_COOKIE } from 'kat/lib/constants/cookieConstants'
import { envConfig, initializeEnvConfig } from './functions/envConfig/envConfig'
import { enableIndexedDbPersistence, getFirestore } from 'firebase/firestore'
import {
  getMessaging,
  MessagePayload,
  onMessage,
  isSupported,
} from 'firebase/messaging'
import { sentrySdk } from 'kat/lib/sdks/sentry/sentrySdk'
import { SentrySeverity } from 'kat/lib/sdks/sentry/SentrySeverity'
import { getFingerPrint } from 'kat/lib/utilities/getFingerPrint'
import { SetWindowMetaAction } from 'kat/lib/actionCreators/types/SetWindowMetaAction'
import { logAnalyticsEvent } from 'utilities/logAnalyticsEvent'
import { LocationWatcher } from 'kat/lib/com/watcher/LocationWatcher'
import { appRoutes } from 'functions/routes/appRoutes'
import { queryNames } from 'functions/routes/queryNames'
import { ErrorBoundaryWrapper } from 'kat/lib/com/wrapper/ErrorBoundaryWrapper'
import { IndexRouter } from 'com/router/IndexRouter'
import { clientMeta } from 'kat/lib/utilities/clientMeta'
import { LayoutTypesExtra } from 'functions/routes/types/LayoutTypesExtra'

const cookieValues: CookieStorage | undefined = cookieStore.get(
  PRIMARY_STORAGE_KEY_COOKIE
)

const PAD_PALS = 'NotePals'
if (window.appName === PAD_PALS) {
  initializeEnvConfig({ appName: PAD_PALS })
}

initalizeSdks({ appName: envConfig().appName })
// import { createWindowClickAction } from 'actionCreators/createWindowClickAction'

const rootElement = document.getElementById('root') as HTMLElement
const history = createBrowserHistory()

const store = createAppStore({
  rootReducer: rootReducer as any,
  flows,
  history,
  reduxLogger,
  enableLogger:
    cookieValues?.debuggerModeEnabled ||
    envConfig().environment === 'local' ||
    envConfig().environment === 'development',
})

const doubleCheckHeight = (height: number) => {
  const check = () => {
    const currentHeight = getViewPortHeight()

    if (height !== currentHeight) {
      dispatchWindowResize()
    }
  }

  check()
  window.setTimeout(check, 1000) // in case the correct height is set after 1 seconds (hack solution for IOS chrome that doesnt have the correct height)
}

const dispatchWindowResize = (e?: any) => {
  const viewPortWidth = getViewPortWidth()
  const viewPortHeight = getViewPortHeight()
  const width = e?.target?.outerWidth || viewPortWidth
  const height = e?.target?.outerHeight || viewPortHeight

  store.dispatch<WindowResizeAction>({
    type: 'WINDOW_RESIZE',
    description: 'index',
    values: {
      width,
      height,
    },
  })

  doubleCheckHeight(height)
}

window.onresize = dispatchWindowResize
window.storeDispatch = store.dispatch
window.onStoreReady?.() // this might get called before native injects JS
window.logAnalyticsEvent = logAnalyticsEvent

window.addEventListener('error', (e) => {
  if (
    e.message.includes(
      'Connection to Indexed Database server lost. Refresh the page to try again'
    )
  ) {
    sentrySdk().captureException('index.tsx reload error')
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }
})

dispatchWindowResize()

const watchMessage = async () => {
  const messagingSupported = await isSupported()

  if (messagingSupported) {
    onMessage(getMessaging(), ({ data }: MessagePayload) => {
      const { title, body } = data as unknown as PushNotification

      new Notification(title, {
        body,
        vibrate: [300, 100, 400, 100, 400, 100, 400],
        icon: '/favicon.ico',
      }).addEventListener('click', () => {
        // Stuck here, need to determine to focus on another window or open
      })
    })
  }
}

watchMessage()

const dispatchCloseModalWithEsc = (event?: KeyboardEvent) => {
  if (event && event.keyCode === 27) {
    store.dispatch<ToggleAction>({
      description: 'index',
      type: 'TOGGLE',
      toggles: {
        showModal: false,
      },
    })
  }
}

window.onkeypress = dispatchCloseModalWithEsc

const providerApp = (
  <ErrorBoundaryWrapper>
    <Provider store={store}>
      <LocationWatcher<LayoutTypesExtra>
        history={history}
        appRoutes={appRoutes}
        queryNames={queryNames}
      />
      <Router location={history.location} navigator={history}>
        <IndexRouter />
      </Router>
    </Provider>
  </ErrorBoundaryWrapper>
)

if (rootElement.hasChildNodes()) {
  hydrate(providerApp, rootElement)
} else {
  render(providerApp, rootElement)
}

const checkNewVersion = async () => {
  try {
    if (cookieValues?.debuggerModeEnabled) {
      console.log('checkNewVersion')
    }
    const response = await Axios.get(
      envConfig().publicUrl + '/manifest.json?bustCache=' + Math.random()
    )
    if (
      Number(envConfig().appVersion) < Number(response.data['minimum_version'])
    ) {
      try {
        await unregister()
        window.clearCache?.() // for native
        window.location.reload()
      } catch (e) {
        sentrySdk().captureMessage(
          `index.tsx error: ${e?.message}`,
          SentrySeverity.Critical
        )
      }
    }
  } catch {
    // nothing for now
  }
}

;(async () => {
  const fingerPrint = await getFingerPrint()

  store.dispatch<SetWindowMetaAction>({
    description: 'index',
    type: 'SET_WINDOW_META',
    values: { fingerPrint },
  })
})()

if (!clientMeta.isReactSnap) {
  checkNewVersion() // may happen too quickly on native
  setInterval(checkNewVersion, 60000) // check for new version every 1 minute
}

serviceWorkerRegister({
  isLocalEnv: envConfig().environment === 'local',
  onSuccess: () => {
    console.log('Successfully registered')
    // dont need this, commenting out if we need in the future
    // store.dispatch<SetWindowMetaAction>({
    //   description: 'index',
    //   type: 'SET_WINDOW_META',
    //   values: {
    //     serviceWorkerRegistered: true,
    //   },
    // })
  },
  onError: (error) => {
    sentrySdk().captureMessage(
      `serviceWorkerRegister in index.tsx: ${error}`,
      SentrySeverity.Critical
    )
  },
})
// if (true) {
//   serviceWorkerRegister()
// } else {
//   unregister()
// }

if (
  window.location.href.includes('enablePersistence') ||
  navigator.userAgent.includes('WebView')
) {
  if (cookieValues?.debuggerModeEnabled) {
    console.log('enablePersistence')
  }

  try {
    enableIndexedDbPersistence(getFirestore(), {
      // forceOwnership: true,
    })
  } catch (e) {
    if (cookieValues?.debuggerModeEnabled) {
      alert('enablePersistence error')
      alert(e.message)
    }
  }
}
