import { createLocationAction } from 'actionCreators/createLocationAction'
import { createErrorAction } from 'kat/lib/actionCreators/createErrorAction'
import { HistoryAction } from 'kat/lib/actionCreators/types/HistoryAction'
import { takeGaurd } from 'kat/lib/utilities/takeGaurd'
import { SubmitNewLabelAction } from './../../../actionCreators/submit/SubmitNewLabelAction'
import { PatchWorkspacesRequestActionDefault } from '../../../actionCreators/types/PatchWorkspacesRequestActionDefault'
import { put, select } from 'redux-saga/effects'
import { appRoutes } from 'functions/routes/appRoutes'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'kat/lib/state/selectState'
import { createId } from 'kat/lib/utilities/create/createId'
import { Label } from '../../../functions/types/Label'

const description = 'submitNewLabelF'

export const submitNewLabelF = function* () {
  while (1) {
    const action: SubmitNewLabelAction = yield takeGaurd<SubmitNewLabelAction>(
      'SUBMIT_NEW_LABEL_ACTION'
    )
    const { icon, displayName } = action.values
    const state: StateReducers = yield select(selectState)
    const currentWorkspace = state.currentWorkspace
    const currentWorkspaceLabels = currentWorkspace?.labels || []
    const workspaceId = state.currentWorkspaceId || ''
    const query = state.currentLocation?.query
    const trimmedDisplayName = displayName?.trim()

    if (workspaceId && trimmedDisplayName && icon) {
      const newLabels: Label[] = [
        ...currentWorkspaceLabels,
        {
          id: createId(),
          displayName: trimmedDisplayName,
          icon,
        },
      ]

      yield put<PatchWorkspacesRequestActionDefault>({
        type: 'PATCH_WORKSPACES_REQUEST',
        description,
        requestValues: {
          items: {
            [workspaceId]: {
              data: {
                labels: newLabels,
              },
            },
          },
        },
      })

      yield put<HistoryAction>(
        createLocationAction({
          description,
          method: 'push',
          query,
          pathname: appRoutes.labelsRoute.pathname,
        })
      )
    } else {
      yield put(createErrorAction({ description }))
    }
  }
}
