import { EnvConfig } from './../functions/envConfig/types/EnvConfig'
import { stripeSdk } from 'kat/lib/sdks/stripe/stripeSdk'
import { firebaseSdkInit } from 'kat/lib/sdks/firebase/firebaseSdk'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/messaging'
import { sentrySdk } from 'kat/lib/sdks/sentry/sentrySdk'
import { apiRoutesBase } from 'kat/lib/routes/apiRoutesBase'

import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  SENTRY_DSN,
  STRIPE_ID,
  PUBLIC_VAPID_KEY,
  FIREBASE_FUNCTIONS_BASE_URL,
  FIREBASE_AUTH_DOMAIN_NOTEPALS,
  MIX_PANEL_TOKEN,
} from 'constants/sdkConstants'

interface Props {
  appName?: EnvConfig['appName']
}

export const initalizeSdks = ({ appName }: Props) => {
  const isAdmin = appName === 'Admin'
  const authDomain =
    (appName === 'NotePals'
      ? FIREBASE_AUTH_DOMAIN_NOTEPALS
      : FIREBASE_AUTH_DOMAIN) || `${FIREBASE_PROJECT_ID}.firebaseapp.com`

  firebaseSdkInit({
    apiKey: FIREBASE_API_KEY,
    authDomain,
    databaseURL: FIREBASE_DATABASE_URL,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
    publicVapidKey: PUBLIC_VAPID_KEY,
  })

  apiRoutesBase.setBaseUrl(FIREBASE_FUNCTIONS_BASE_URL)

  if (!isAdmin) {
    stripeSdk.init({ stripeId: STRIPE_ID }) // also known as publishableKey

    window.mixpanel?.init(MIX_PANEL_TOKEN, {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage',
    })

    if (SENTRY_DSN) {
      sentrySdk.init({ dsn: SENTRY_DSN })
    }
  }
}
