import { collectionNames } from '../../collection/collectionNames'

interface Props {
  workspaceId: string
  taskId: string
  messageId: string
}

export const getMessageStoragePath = ({
  workspaceId,
  taskId,
  messageId,
}: Props): string => {
  return `${collectionNames.workspaces}/${workspaceId}/${collectionNames.tasks}/${taskId}/${collectionNames.messages}/${messageId}/`
}
