import React, { FC, Suspense, lazy } from 'react'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { useSelector } from 'react-redux'
import { StylesWatcher } from 'com/watcher/StylesWatcher'
import { LoginRedirectWatcher } from 'com/watcher/LoginRedirectWatcher'
import { AuthChangeWatcher } from 'kat/lib/com/watcher/AuthChangeWatcher'
import { useSetAppUiState } from 'hooks/useSetAppUiState'
const AppMain = lazy(() => import('com/main/AppMain'))
const MarketingLayout = lazy(() => import('com/layout/MarketingLayout'))
const PrintLayout = lazy(() => import('com/layout/PrintLayout'))

interface IndexRouterProps {}

const setMixPanelUserId = (userId: string) => {
  // Set this to a unique identifier for the user performing the event.
  window.mixpanel?.identify?.(userId)
}

export const IndexRouter: FC<IndexRouterProps> = () => {
  useSetAppUiState()

  const rootRoute = useSelector((state: StateReducers) => {
    return state.currentLocation?.isRoutes?.rootRoute
  })

  const isPrintLayout = useSelector((state: StateReducers) => {
    const isRoutes = state.currentLocation?.isRoutes
    return isRoutes?.useLayout === 'print'
  })

  // need a div otherwise will have rendering issues with react snap, unsure why
  return (
    <div>
      <StylesWatcher />
      <LoginRedirectWatcher />
      <AuthChangeWatcher onSignIn={setMixPanelUserId} />

      {rootRoute ? (
        <Suspense fallback={<></>}>
          <MarketingLayout />
        </Suspense>
      ) : isPrintLayout ? (
        <Suspense fallback={<></>}>
          <PrintLayout />
        </Suspense>
      ) : (
        <Suspense fallback={<></>}>
          <AppMain />
        </Suspense>
      )}
    </div>
  )
}
