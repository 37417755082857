import { WhichMain } from './../../types/WhichMain'
import { StateReducers } from 'state/reducers/types/StateReducers'

interface Props {
  state: StateReducers
}

interface Props {
  state: StateReducers
}

export const getWhichMain = ({ state }: Props): WhichMain | undefined => {
  const whichMain = state.currentLocation?.query?.whichMain
  const isRoutes = state.currentLocation?.isRoutes
  const workspaceHomeRoute = isRoutes?.workspaceHomeRoute
  const activitiesRoute = isRoutes?.activitiesRoute
  const levelRoute = isRoutes?.levelRoute
  const archiveRoute = isRoutes?.archiveRoute
  const calendarRoute = isRoutes?.calendarRoute
  const searchRoute = isRoutes?.searchRoute

  if (whichMain === 'home' || workspaceHomeRoute) {
    return 'home'
  }

  if (whichMain === 'archive' || archiveRoute) {
    return 'archive'
  }

  if (whichMain === 'activities' || activitiesRoute) {
    return 'activities'
  }

  if (whichMain === 'level' || levelRoute) {
    return 'level'
  }

  if (whichMain === 'search' || searchRoute) {
    return 'search'
  }

  if (whichMain === 'calendar' || calendarRoute) {
    return 'calendar'
  }

  if (!whichMain) {
    return undefined
  }

  throw new Error('whichMain has an invalid value')
}
