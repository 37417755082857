import { THUMB_FILE_NAME_PREFIX } from 'kat/lib/constants/fixConstants'
import { getMessageStoragePath } from './getMessageStoragePath'
import { getCanBeThumb } from 'kat/lib/utilities/get/getCanBeThumb'

interface Props {
  workspaceId: string
  taskId: string
  messageId: string
  fileName: string
}

export const getMessageThumbPath = ({
  workspaceId,
  taskId,
  messageId,
  fileName,
}: Props): string | undefined => {
  const path = getMessageStoragePath({ workspaceId, taskId, messageId })
  const canBeThumb = getCanBeThumb(fileName)

  return canBeThumb ? `${path}${THUMB_FILE_NAME_PREFIX}${fileName}` : undefined
}
