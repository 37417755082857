import { taskListReducer } from './taskListReducer'
import { searchTextReducer } from './searchTextReducer'
import { cachedGettersReducer } from './cachedGettersReducer'
import { combinedReducersBase } from 'kat/lib/state/combinedReducersBase'
import { combineReducers } from 'redux'
import { messagesReducer } from './messagesReducer'
import { requestsReducer } from './requestsReducer'
import { StateReducers } from './types/StateReducers'
import { tasksReducer } from './tasksReducer'
import { plansReducer } from './plansReducer'
import { workspacesReducers } from './workspacesReducers'
import { filesToUploadReducer } from './filesToUploadReducer'
import { SignOutSuccessAction } from 'kat/lib/actionCreators/auth/types/SignOutSuccessAction'
import { currentTaskReducer } from './currentTaskReducer'
import { calendarEventsReducer } from './calendarEventsReducer'
import { calendarSetupsReducer } from './calendarSetupsReducer'
import { calendarListReducer } from './calendarListReducer'
import { currentDailyItemReducer } from './currentDailyItemReducer'
import { dailyItemsReducer } from './dailyItemsReducer'

const appReducer = combineReducers<StateReducers>({
  ...combinedReducersBase,
  messages: messagesReducer,
  requests: requestsReducer,
  tasks: tasksReducer,
  dailyItems: dailyItemsReducer,
  plans: plansReducer,
  workspaces: workspacesReducers,
  currentTask: currentTaskReducer,
  currentDailyItem: currentDailyItemReducer,
  filesToUpload: filesToUploadReducer,
  cachedGetters: cachedGettersReducer,
  searchText: searchTextReducer,
  taskList: taskListReducer,
  calendarEvents: calendarEventsReducer,
  calendarSetups: calendarSetupsReducer,
  calendarList: calendarListReducer,
})

export const initialState = appReducer({}, { type: 'INITIAL' }) as StateReducers

export const rootReducer = (
  state: StateReducers,
  action: SignOutSuccessAction
) => {
  if (action.type === 'SIGN_OUT_SUCCESS') {
    const sessionStorage = state.sessionStorage || undefined
    const cookieStorage = state.cookieStorage || undefined
    const isEndToEndTesting = sessionStorage && sessionStorage.isEndToEndTesting
    const windowMeta = state.windowMeta || undefined
    const currentLocation = state.currentLocation || undefined

    const newState: StateReducers = {
      ...initialState,
      sessionStorage: {
        ...initialState.sessionStorage,
        isEndToEndTesting,
      },
      currentLocation,
      windowMeta,
      cookieStorage,
    }

    return appReducer(newState, action)
  }

  return appReducer(state, action)
}
