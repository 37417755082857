export const ORPHANED_STATUS_KEY = 'orphaned-123asdfg'
export const SCRATCH_PAD_KEY = 'scratchPadKey-123asdfg'
export const GENERAL_CHAT_TASK_ITEM_ID = 'generalChat-123asdfg'
export const DAILY_LOG_KEY_PREFIX = 'daily-'
export const SCRATCH_PAD_SUMMARY = `Notepad`
export const TO_DO_STATUS = '✅ To do'
export const IDEAS_STATUS = '💡 Ideas'
export const INFORMATION_STATUS = '📋 Record keeping'
export const PLAN_STATUS = '🗂️ Planning'
export const DEFAULT_WORKSPACE_NAME = 'Group name'

export const FUN_THINGS = '🎉 Fun Things'
export const FINANCIAL_GOALS_STATUS = '💸 Financial Goals'
export const HEALTH_GOALS = '💪 Health Goals'
export const WEEKLY_GOALS = '📅 Weekly Goals'
export const BUCKET_LIST = '🌍 Bucket List'
export const RELATIONSHIP_GOALS = '❤️ Relationship Goals'
export const WORKSPACE_FOOTER_HEIGHT = 54
export const LABEL_IN_PROGRESS_ID = '1702ef84'
export const LABEL_DONE_ID = '1f8bf467'
export const LABEL_BLOCKED_ID = '001b185d'
export const LABEL_HALF_DONE_ID = '101b195p'
export const LABEL_ALMOST_DONE_ID = '202a135r'

export const LABEL_DISPLAY_NAMES = {
  [LABEL_IN_PROGRESS_ID]: 'Started',
  [LABEL_DONE_ID]: 'Complete',
  [LABEL_HALF_DONE_ID]: 'Halfway',
  [LABEL_ALMOST_DONE_ID]: 'Almost there',
  [LABEL_BLOCKED_ID]: 'Blocked',
}
