import { PatchPlansRequestAction } from './../../actionCreators/patch/PatchPlans/types/PatchPlansRequestAction'
import { PostPlansRequestAction } from './../../actionCreators/post/PostPlans/types/PostPlansRequestAction'
import { GetPlansSuccessAction } from './../../actionCreators/get/GetPlans/types/GetPlansSuccessAction'
import { createGenericReducer } from 'kat/lib/state/reducers/createGenericReducer'
import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'
import { PlansReducer } from './types/PlansReducer'
import { Plan } from '../../functions/types/Plan'

const clearWorkspaceScopeAction: ClearWorkspaceScopeAction['type'] =
  'CLEAR_WORKSPACE_SCOPE'
const getPlansSuccess: GetPlansSuccessAction['type'] =
  'GET_PLANS_SUCCESS_ACTION'
const postPlansRequestAction: PostPlansRequestAction['type'] =
  'POST_PLANS_REQUEST_ACTION'
const patchPlansRequestAction: PatchPlansRequestAction['type'] =
  'PATCH_PLANS_REQUEST_ACTION'

export const plansReducer = createGenericReducer<Plan, PlansReducer>({
  getSuccessAction: getPlansSuccess,
  postRequestAction: postPlansRequestAction,
  patchRequestAction: patchPlansRequestAction,
  clearActions: [clearWorkspaceScopeAction],
  // debug: true,
})
