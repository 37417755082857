import { put } from 'redux-saga/effects'
import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'
import { createSetSessionStorageAction } from 'actionCreators/createSetSessionStorageAction'
import { takeGaurd } from 'kat/lib/utilities/takeGaurd'

export const clearWorkspaceScopeF = function* () {
  while (1) {
    yield takeGaurd<ClearWorkspaceScopeAction>('CLEAR_WORKSPACE_SCOPE')

    yield put(
      createSetSessionStorageAction({
        description: 'clearWorkspaceScopeF',
        sessionStorage: {
          taskFilters: null,
        },
      })
    )
  }
}
