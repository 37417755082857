import { SetSearchTextInputFocusAction } from './../../actionCreators/SetSearchTextInputFocusAction'
import { SearchTextReducer } from './types/SearchTextReducer'
import { SearchTextDoneAction } from '../../actionCreators/SearchTextDoneAction'
import { LocationChangeActionBase } from 'kat/lib/actionCreators/types/LocationChangeActionBase'

export const searchTextReducer = (
  state?: SearchTextReducer,
  action?:
    | SearchTextDoneAction
    | SetSearchTextInputFocusAction
    | LocationChangeActionBase
): SearchTextReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case 'LOCATION_CHANGE': {
      return {
        ...state,
        searchText: undefined,
      }
    }
    case 'SEARCH_TEXT_DONE_ACTION': {
      return {
        ...state,
        results: action.results,
        searchText: action.searchText,
      }
    }

    case 'SET_SEARCH_TEXT_INPUT_FOCUS_ACTION': {
      return {
        ...state,
        isInputFocused: action.isInputFocused,
      }
    }

    default:
      return state || null
  }
}
