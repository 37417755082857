import { getTaskItemMessageRefClient } from './../get/getTaskItemMessageRefClient'
import { FirestoreClient } from 'kat/lib/sdks/firebase/types/FirestoreClient'
import { Message } from '../../../types/Message'
import { updateDoc } from 'firebase/firestore'

interface Props {
  firestore: FirestoreClient
  workspaceId: string
  messageId: string
  message: Message
}

export const updateTaskItemMessageClient = ({
  firestore,
  workspaceId,
  messageId,
  message,
}: Props): Promise<void> => {
  const ref = getTaskItemMessageRefClient({
    firestore,
    workspaceId,
    messageId,
  })

  return updateDoc(ref, message)
}
