import { getCurrentTaskItemIds } from './../state/getters/getCurrentTaskItemIds';
import { AddTaskItemBelowAction } from './../actionCreators/AddTaskItemBelowAction';
import { put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'kat/lib/state/selectState'
import { takeGaurd } from 'kat/lib/utilities/takeGaurd'
import { ToggleAction } from '../actionCreators/ToggleAction'

const description = 'addTaskItemBelowFlow'

// when we want to add below an item but have the same behavior as add item above
export const addTaskItemBelowFlow = function* () {
  while (1) {
    const action: AddTaskItemBelowAction = yield takeGaurd<AddTaskItemBelowAction>(
      'ADD_TASK_ITEM_BELOW_ACTION'
    )

    const { taskItemId } = action

    const state: StateReducers = yield select(selectState)

    const { taskItemIds } =
    getCurrentTaskItemIds({ state })

    const taskItemIdIndex = taskItemIds.findIndex((findTaskItemId) => {
      return taskItemId === findTaskItemId
    })

    
    yield put<ToggleAction>({
      type: 'TOGGLE',
      description,
      toggles: {
        showTaskItemAction: {
          type: 'addAbove',
          taskItemId: taskItemIds[taskItemIdIndex + 1] || null, // null to add to bottom
        },
      },
    })
  }
}
