import { createLocationAction } from 'actionCreators/createLocationAction'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { getBackLocation } from './../state/getters/getBackLocation'
import { put, select } from 'redux-saga/effects'
import { takeGaurd } from 'kat/lib/utilities/takeGaurd'
import { GoBackAction } from 'kat/lib/actionCreators/GoBackAction'
import { selectState } from 'kat/lib/state/selectState'
import { HistoryAction } from 'kat/lib/actionCreators/types/HistoryAction'
import { ToggleAction } from 'actionCreators/ToggleAction'
import { TogglesReducer } from 'state/reducers/types/TogglesReducer'

const description = 'goBackFlow'
export const goBackFlow = function* () {
  while (1) {
    yield takeGaurd<GoBackAction>('GO_BACK_ACTION')

    const state: StateReducers = yield select(selectState)
    const { route, query, withCurrentQuery } = getBackLocation({ state })
    const useQuery = withCurrentQuery ? state.currentLocation?.query : query
    let isSomethingToggledOn = false
    const toggleAllOffMutable: TogglesReducer = {}

    for (const [key, toggle] of Object.entries(state.toggles || {})) {
      if (toggle) {
        isSomethingToggledOn = true
      }
      toggleAllOffMutable[key] = false
    }

    if (isSomethingToggledOn) {
      yield put<ToggleAction>({
        type: 'TOGGLE',
        description,
        toggles: toggleAllOffMutable,
      })
    } else if (route) {
      yield put<HistoryAction>(
        createLocationAction({
          description,
          method: 'push',
          query: useQuery,
          pathname: route?.pathname,
        })
      )
    }
  }
}
