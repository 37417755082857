import { createErrorAction } from 'kat/lib/actionCreators/createErrorAction';
import { SubmitDeleteMessageAction } from '../../../actionCreators/submit/SubmitDeleteMessageAction'
import { takeGaurd } from 'kat/lib/utilities/takeGaurd'
import { put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { MessagesReducer } from 'state/reducers/types/MessagesReducer'
import { selectState } from 'kat/lib/state/selectState'
import { patchMessagesSaga } from '../../sagas/patch/patchMessagesSaga'
import { clientServerTimestamp } from 'kat/lib/sdks/firebase/clientServerTimestamp'

const description = 'submitDeleteMessageFlow'

export const submitDeleteMessageFlow = function* () {
  while (1) {
    const action: SubmitDeleteMessageAction =
      yield takeGaurd<SubmitDeleteMessageAction>('SUBMIT_DELETE_MESSAGE_ACTION')
    const state: StateReducers = yield select(selectState)
    const { messageId } = action
    const messageReducer = messageId ? state.messages?.[messageId] : undefined
    const message = messageId ? state.messages?.[messageId]?.data : undefined
    const workspaceId = messageReducer?.workspaceId
    const taskId = messageReducer?.taskId
    if (messageId && taskId && workspaceId && message) {
      // TODO: readd for task item message yield deleteMessageFiles({ workspaceId, taskId, messageId })

      const items: MessagesReducer = {
        [messageId]: {
          data: {
            deleted: clientServerTimestamp(),
          },
          taskId,
          workspaceId,
        },
      }

      yield spawn(patchMessagesSaga, {
        items,
      })
    } else {
      yield put(createErrorAction({ description }))
    }
  }
}
