import { Message } from '../../../types/Message'
import { DocumentReferenceClient } from 'kat/lib/sdks/firebase/types/DocumentReferenceClient'
import { FirestoreClient } from 'kat/lib/sdks/firebase/types/FirestoreClient'
import { getTaskItemMessagesRefClient } from './getTaskItemMessagesRefClient'
import { doc } from 'firebase/firestore'

interface Props {
  firestore: FirestoreClient
  workspaceId: string
  messageId: string
}

export const getTaskItemMessageRefClient = ({
  firestore,
  workspaceId,
  messageId,
}: Props): DocumentReferenceClient<Message> => {
  const ref = getTaskItemMessagesRefClient({ firestore, workspaceId })
  return doc(ref, messageId)
}
