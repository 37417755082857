import { getTaskFileStoragePathV2 } from './getTaskFileStoragePathV2'

interface Props {
  workspaceId: string
  fileName: string
}

export const getTaskFileOriginalPathV2 = ({
  workspaceId,
  fileName,
}: Props): string => {
  const path = getTaskFileStoragePathV2({ workspaceId })

  return `${path}${fileName}`
}
