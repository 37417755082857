import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { useDispatch } from 'react-redux'
import { SetCookieStorageActionBase } from 'kat/lib/actionCreators/types/SetCookieStorageActionBase'
import { setAppStyles } from 'styles/appStyles'
import { getIsMediumScreen } from 'kat/lib/state/getters/getIsMediumScreen'
import { getGlobalStylesString } from 'kat/lib/styles/getGlobalStylesString'
import { injectGlobal } from 'emotion'

interface StylesWatcherProps {
  styles?: string
}

const description = 'StylesWatcher'

export const StylesWatcher: FC<StylesWatcherProps> = () => {
  const dispatch = useDispatch()
  const refreshForColorTheme = useSelector((state: StateReducers) => {
    return (
      !state.cookieStorage?.uiColorTheme &&
      state.currentUnscopeUser?.uiColorTheme &&
      state.cookieStorage
        ?.signedIntoDashboard /* need this otherwise, logout will trigger this */
    )
  })

  const uiColorTheme = useSelector((state: StateReducers) => {
    return state.cookieStorage?.uiColorTheme || undefined
  })

  const isPrintLayout = useSelector((state: StateReducers) => {
    const isRoutes = state.currentLocation?.isRoutes
    return isRoutes?.useLayout === 'print'
  })

  useEffect(() => {
    if (refreshForColorTheme) {
      dispatch<SetCookieStorageActionBase>({
        type: 'SET_COOKIE_STORAGE',
        description,
        values: {
          uiColorTheme: 'DarkMode',
        },
      })
      setAppStyles('DarkMode')
    }
  }, [refreshForColorTheme, dispatch])

  const isMediumScreen = useSelector((state: StateReducers) => {
    return getIsMediumScreen({ state })
  })

  useEffect(() => {
    if (document) {
      document.getElementsByTagName('html')[0].style.fontSize = isMediumScreen
        ? '16px'
        : '18px'
    }
  }, [isMediumScreen])

  useEffect(() => {
    const styles = getGlobalStylesString({ isMediumScreen })
    injectGlobal(`
      :root {
        ${styles} ${
      isPrintLayout
        ? ` @page {
            size: auto;   /* auto is the initial value */
            margin: 0;  /* this affects the margin in the printer settings */
         }`
        : ''
    }
      }
    `)
  }, [isMediumScreen, uiColorTheme, isPrintLayout])

  return null
}
