import { getCurrentTask } from './getCurrentTask'
import { StateReducers } from 'state/reducers/types/StateReducers'

interface Props {
  state: StateReducers
  taskId?: string
}

interface Return {
  taskItemIds: string[]
  headingTaskItemIds: string[]
  bigHeadingTaskItemIds: string[]
  checkedTaskItemIds: string[]
  uncheckedTaskItemIds: string[]
  bulletItemIds: string[]
}

export const getCurrentTaskItemIds = ({ state, taskId }: Props): Return => {
  const tasks = getCurrentTask({ state, taskId })
  let taskItemIdsMutable: string[] = []
  let headingTaskItemIdsMutable: string[] = []
  let bigHeadingTaskItemIdsMutable: string[] = []
  let checkedTaskItemIdsMutable: string[] = []
  let uncheckedTaskItemIdsMutable: string[] = []
  let bulletItemIdsMutable: string[] = []

  tasks?.taskItems?.forEach(({ id, isHeading, useIcon }) => {
    if (id) {
      taskItemIdsMutable.push(id)

      if (isHeading === 1) {
        headingTaskItemIdsMutable.push(id)
      } else if (isHeading === 2) {
        bigHeadingTaskItemIdsMutable.push(id)
      }

      if (useIcon === 'checked') {
        checkedTaskItemIdsMutable.push(id)
      } else if (useIcon === 'unchecked') {
        uncheckedTaskItemIdsMutable.push(id)
      } else if (!isHeading) {
        bulletItemIdsMutable.push(id)
      }
    }
  })

  return {
    taskItemIds: taskItemIdsMutable,
    headingTaskItemIds: headingTaskItemIdsMutable,
    bigHeadingTaskItemIds: bigHeadingTaskItemIdsMutable,
    checkedTaskItemIds: checkedTaskItemIdsMutable,
    uncheckedTaskItemIds: uncheckedTaskItemIdsMutable,
    bulletItemIds: bulletItemIdsMutable,
  }
}
