import { selectState } from '../state/reducers/selectState'
import { takeGaurd } from 'kat/lib/utilities/takeGaurd'
import { put, select } from 'redux-saga/effects'
import { SaveTaskItemSummaryAction } from '../actionCreators/SaveTaskItemSummaryAction'
import { StateReducers } from '../state/reducers/types/StateReducers'
import { SavedTaskItem } from '../types/SavedTaskItem'
import { SetSessionStorageAction } from '../actionCreators/SetSessionStorageAction'

const description = 'saveTaskItemsFlow'

export const saveTaskItemsFlow = function* () {
  while (1) {
    const action: SaveTaskItemSummaryAction =
      yield takeGaurd<SaveTaskItemSummaryAction>(
        'SAVE_TASK_ITEM_SUMMARY_ACTION'
      )
    const state: StateReducers = yield select(selectState)

    let newSavedTaskItems: SavedTaskItem[] = []
    
    if (action.summary) {
      newSavedTaskItems = [
        {
          summary: action.summary,
          taskItemId: action.taskItemId || null,
          createdMs: Date.now(),
        },
        ...(state.sessionStorage?.savedTaskItems || []),
      ].slice(0, 10)
    } else {
      newSavedTaskItems = state.sessionStorage?.savedTaskItems?.filter(({taskItemId}) =>{
        return taskItemId !== action.taskItemId
      }) || []
    }

    yield put<SetSessionStorageAction>({
      description,
      type: 'SET_SESSION_STORAGE',
      sessionStorage: {
        savedTaskItems: newSavedTaskItems,
      },
    })
  }
}
