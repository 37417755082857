import { LocationChangeActionBase } from 'kat/lib/actionCreators/types/LocationChangeActionBase'
import { SetCheckedTaskIdsAction } from './../../actionCreators/set/SetCheckedTaskIdsAction'
import { SetClearCheckedTaskIdsAction } from './../../actionCreators/set/SetClearCheckedTaskIdsAction'
import { TaskListReducer } from './types/TaskListReducer'
import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'
import { SetCurrentTaskAction } from './../../actionCreators/set/SetCurrentTaskAction'

export const taskListReducer = (
  state?: TaskListReducer | null,
  action?:
    | SetCurrentTaskAction
    | ClearWorkspaceScopeAction
    | SetCheckedTaskIdsAction
    | SetClearCheckedTaskIdsAction
    | LocationChangeActionBase
): TaskListReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case 'SET_CLEAR_CHECKED_TASK_IDS_ACTION':
    case 'LOCATION_CHANGE': {
      if (state) {
        return {
          ...state,
          checkedTaskIds: [],
        }
      }

      return null
    }

    case 'SET_CHECKED_TASK_IDS_ACTION': {
      if (state?.disableCheckUnixSeconds) {
        const currentUnixSeconds = new Date().getTime() / 1000
        const timePassed = currentUnixSeconds - state?.disableCheckUnixSeconds
        if (timePassed < 0.5) {
          // hack because we cant prevent long press propagation
          return state
        }
      }
      let newCheckedTaskIdsMutable: string[] = []

      action.taskIds.forEach((taskId) => {
        if (!state?.checkedTaskIds?.includes(taskId)) {
          newCheckedTaskIdsMutable.push(taskId)
        }
      })

      state?.checkedTaskIds?.forEach((taskId) => {
        if (!action.taskIds?.includes(taskId)) {
          newCheckedTaskIdsMutable.push(taskId)
        }
      })

      return {
        ...state,
        checkedTaskIds: newCheckedTaskIdsMutable,
        disableCheckUnixSeconds: action.disableCheckUnixSeconds || 0,
      }
    }

    case 'CLEAR_WORKSPACE_SCOPE': {
      return null
    }

    default:
      return state || null
  }
}
