import { createErrorAction } from 'kat/lib/actionCreators/createErrorAction';
import { patchMessagesSaga } from './../../sagas/patch/patchMessagesSaga'
import { MessagesReducer } from './../../../state/reducers/types/MessagesReducer'
import { SubmitDeleteMessageFileAction } from '../../../actionCreators/submit/SubmitDeleteMessageFileAction'
import { put, select, spawn } from 'redux-saga/effects'
import { clientServerTimestamp } from 'kat/lib/sdks/firebase/clientServerTimestamp'
import { deleteObject, getStorage, ref } from 'firebase/storage'
import { getMessageFileOriginalPath } from 'functions/utilities/get/getMessageFileOriginalPath'
import { getMessageThumbPath } from 'functions/utilities/get/getMessageThumbPath'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'kat/lib/state/selectState'
import { getCurrentTaskId } from '../../../state/getters/getCurrentTaskId'
import { takeGaurd } from 'kat/lib/utilities/takeGaurd'

const description = 'submitDeleteMessageFileFlow'

export const submitDeleteMessageFileFlow = function* () {
  while (1) {
    const action: SubmitDeleteMessageFileAction =
      yield takeGaurd<SubmitDeleteMessageFileAction>(
        'SUBMIT_DELETE_MESSAGE_FILE_ACTION'
      )
    const { fileNames, messageId } = action
    const state: StateReducers = yield select(selectState)
    const taskId = getCurrentTaskId({ state })
    const workspaceId = state.currentWorkspaceId || ''
    const currentUserId = state.currentUserId
    const currentUserDisplayName = state.currentUser?.displayName
    const deleteFilePromisesMutable: Promise<any>[] = []

    fileNames.forEach((fileName) => {
      const originalPath =
        fileName && workspaceId && taskId && messageId
          ? getMessageFileOriginalPath({
              messageId,
              workspaceId,
              taskId,
              fileName,
            })
          : undefined

      const thumbPath =
        fileName && workspaceId && taskId && messageId
          ? getMessageThumbPath({
              messageId,
              workspaceId,
              taskId,
              fileName,
            })
          : undefined
      const storageRef = ref(getStorage())

      if (originalPath) {
        deleteFilePromisesMutable.push(
          deleteObject(ref(storageRef, originalPath))
        )
      }

      if (thumbPath) {
        deleteFilePromisesMutable.push(deleteObject(ref(storageRef, thumbPath)))
      }
    })

    if (deleteFilePromisesMutable.length) {
      try {
        yield Promise.all(deleteFilePromisesMutable)
      } catch {}
    }

    if (taskId && workspaceId && currentUserId && currentUserDisplayName) {
      // need to update so component will refresh
      const items: MessagesReducer = {
        [messageId]: {
          data: {
            edited: clientServerTimestamp(),
          },
          workspaceId,
          taskId,
        },
      }

      yield spawn(patchMessagesSaga, {
        items,
      })
    } else {
      yield put(createErrorAction({ description }))
    }
  }
}
