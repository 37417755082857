import { TaskNotificationGroups } from './types/TaskNotificationGroups'
import { GetWorkspaceUserSuccessAction } from 'kat/lib/actionCreators/get/GetWorkspaceUser/types/GetWorkspaceUserSuccessAction'
import { Notification } from '../../functions/types/Notification'
import { CachedGettersReducer } from './types/CachedGettersReducer'

export const cachedGettersReducer = (
  state?: CachedGettersReducer,
  action?: GetWorkspaceUserSuccessAction<Notification, Notification>
): CachedGettersReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case 'GET_WORKSPACE_USER_SUCCESS':
      const activitiesNotificationsMutable: TaskNotificationGroups = {}

      action.successValues.item.activityNotifications?.forEach(
        ({ taskId, unreadCount = 0, id, userId, taskItemId }, index) => {
          if (!taskId) {
            return
          }
          if (!activitiesNotificationsMutable[taskId]) {
            activitiesNotificationsMutable[taskId] = {
              totalUnreadCount: unreadCount,
              notificationIndexes: [index],
              notificationIds: [id],
              unreadCounts: [unreadCount],
              userIds: [userId],
              taskItemIds: [taskItemId],
            }
          } else {
            activitiesNotificationsMutable[taskId].totalUnreadCount +=
              unreadCount
            activitiesNotificationsMutable[taskId].unreadCounts.push(
              unreadCount
            )
            activitiesNotificationsMutable[taskId].notificationIndexes.push(
              index
            )
            activitiesNotificationsMutable[taskId].notificationIds.push(id)

            activitiesNotificationsMutable[taskId].userIds.push(userId)
            activitiesNotificationsMutable[taskId].taskItemIds.push(taskItemId)
          }
        }
      )

      const messagesNotificationsMutable: TaskNotificationGroups = {}

      action.successValues.item.messageNotifications?.forEach(
        ({ taskId, unreadCount = 0, id, userId, taskItemId }, index) => {
          if (!taskId) {
            return
          }
          if (!messagesNotificationsMutable[taskId]) {
            messagesNotificationsMutable[taskId] = {
              totalUnreadCount: unreadCount,
              notificationIndexes: [index],
              notificationIds: [id],
              unreadCounts: [unreadCount],
              userIds: [userId],
              taskItemIds: [taskItemId],
            }
          } else {
            messagesNotificationsMutable[taskId].totalUnreadCount += unreadCount
            messagesNotificationsMutable[taskId].unreadCounts.push(unreadCount)
            messagesNotificationsMutable[taskId].notificationIndexes.push(index)
            messagesNotificationsMutable[taskId].notificationIds.push(id)
            messagesNotificationsMutable[taskId].userIds.push(userId)
            messagesNotificationsMutable[taskId].taskItemIds.push(taskItemId)
          }
        }
      )

      return {
        ...state,
        taskNotificationGroups: {
          activities: activitiesNotificationsMutable,
          messages: messagesNotificationsMutable,
        },
      }

    default:
      return state || null
  }
}
