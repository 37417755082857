import { SetSessionStorageActionBase } from 'kat/lib/actionCreators/types/SetSessionStorageActionBase'
import { SessionStorage } from '../types/SessionStorage'
import { SET_SESSION_STORAGE } from 'kat/lib/constants/actions'

interface Props
  extends Omit<SetSessionStorageActionBase<SessionStorage>, 'type'> {}

export const createSetSessionStorageAction = ({
  sessionStorage,
  replace,
  description,
}: Props): SetSessionStorageActionBase<SessionStorage> => {
  return {
    description,
    type: SET_SESSION_STORAGE,
    sessionStorage,
    replace,
  }
}
