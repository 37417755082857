import { createGenericReducer } from 'kat/lib/state/reducers/createGenericReducer'
import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'
import { DailyItemModel } from '../../functions/types/DailyItemModel'
import { DailyItemsReducer } from './types/DailyItemsReducer'
import { PostDailyItemsRequestAction } from 'actionCreators/dailyItems/PostDailyItemsRequestAction'
import { DeleteDailyItemsRequestAction } from 'actionCreators/dailyItems/DeleteDailyItemsRequestAction'
import { DeleteDailyItemsSuccessAction } from 'actionCreators/dailyItems/DeleteDailyItemsSuccessAction'
import { PatchDailyItemsRequestAction } from 'actionCreators/dailyItems/PatchDailyItemsRequestAction'
import { GetDailyItemsSuccessAction } from 'actionCreators/dailyItems/GetDailyItemsSuccessAction'
import { ClearWorkspaceWatchedDailyItemsAction } from 'actionCreators/dailyItems/ClearWorkspaceWatchedTasksAction'

const extend = (
  state?: DailyItemsReducer,
  action?: ClearWorkspaceWatchedDailyItemsAction
): DailyItemsReducer | null => {
  if (!action || !state) {
    return state || null
  }

  switch (action.type) {
    case 'CLEAR_WORKSPACE_WATCHED_DAILY_ITEMS_ACTION': {
      return {}
    }
    default:
      return state || null
  }
}

const postRequestAction: PostDailyItemsRequestAction['type'] =
  'POST_DAILY_ITEMS_REQUEST_ACTION'

const patchRequestAction: PatchDailyItemsRequestAction['type'] =
  'PATCH_DAILY_ITEMS_REQUEST_ACTION'

const deleteRequestAction: DeleteDailyItemsRequestAction['type'] =
  'DELETE_DAILY_ITEMS_REQUEST_ACTION'

const deleteSuccessAction: DeleteDailyItemsSuccessAction['type'] =
  'DELETE_DAILY_ITEMS_SUCCESS_ACTION'

const clearWorkspaceScopeAction: ClearWorkspaceScopeAction['type'] =
  'CLEAR_WORKSPACE_SCOPE'

const getDailyItemsSuccess: GetDailyItemsSuccessAction['type'] =
  'GET_DAILY_ITEMS_SUCCESS_ACTION'

export const dailyItemsReducer = createGenericReducer<
  DailyItemModel,
  DailyItemsReducer
>({
  getSuccessAction: getDailyItemsSuccess,
  postRequestAction,
  deleteRequestAction,
  deleteSuccessAction, // TODO: add delete success because another user may have done it.
  patchRequestAction,
  clearActions: [clearWorkspaceScopeAction],
  extend,
  // debug: true,
})
