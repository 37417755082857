import { Task } from 'functions/types/Task'
import { StateReducers } from 'state/reducers/types/StateReducers'

interface Props {
  state: StateReducers
  taskId?: string
}

export const getCurrentTask = ({ state, taskId }: Props): Task | undefined => {
  if (!taskId || taskId === state.currentTask?.taskId) {
    return state.currentTask?.data || undefined
  }

  return state.tasks?.[taskId]?.data
}
