export const collectionDefault = {
  workspaces: 'teams',
  tasks: 'tasks',
  taskItems: 'taskItems', // theres no firestore for this right now
  messages: 'messages',
  taskChanges: 'taskChanges',
  // taskReminders: 'taskReminders',
  globalReminders: 'globalReminders',
  plans: 'plans',
  workspaceUsers: 'userExtensions',
  workspaceChanges: 'changes',
  taskItemMessages: 'taskItemMessages',
  sharedTasks: 'sharedTasks',
  aiSuggestions: 'aiSuggestions',
  calendarEvents: 'calendarEvents',
  calendarSetups: 'calendarSetups',
  reminders: 'reminders',
  sendNotifications: 'sendNotifications',
  levelPoints: 'levelPoints',
  dailyItems: 'dailyItems',
  taskSuggestions: 'taskSuggestions',
}
