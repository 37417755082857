import { Message } from '../../../types/Message'
import { CollectionReferenceClient } from 'kat/lib/sdks/firebase/types/CollectionReferenceClient'
import { FirestoreClient } from 'kat/lib/sdks/firebase/types/FirestoreClient'
import { collectionNames } from '../../../collection/collectionNames'
import { collection } from 'firebase/firestore'
import { getWorkspaceRefClient } from 'kat/lib/functions/utilities/ref/get/getWorkspaceRefClient'

interface Props {
  firestore: FirestoreClient
  workspaceId: string
}

export const getTaskItemMessagesRefClient = ({
  firestore,
  workspaceId,
}: Props): CollectionReferenceClient<Message> => {
  const ref = getWorkspaceRefClient({ firestore, workspaceId })
  return collection(ref, collectionNames.taskItemMessages)
}
