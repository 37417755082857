import { RequestsReducer } from './types/RequestsReducer'
import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'

export const requestsReducer = (
  state?: RequestsReducer,
  action?: ClearWorkspaceScopeAction
): RequestsReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case 'CLEAR_WORKSPACE_SCOPE':
      return {
        ...state,
        gotInvites: undefined,
      }

    default:
      return state || null
  }
}
