import { UploadRequestAction } from 'kat/lib/actionCreators/Upload/UploadRequestAction'
import { getTaskFileOriginalPathV2 } from './../../../functions/utilities/get/getTaskFileOriginalPathV2'
import { getCurrentTaskId } from './../../../state/getters/getCurrentTaskId'
import { put, select } from 'redux-saga/effects'
import { createId } from 'kat/lib/utilities/create/createId'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'kat/lib/state/selectState'
import { takeGaurd } from 'kat/lib/utilities/takeGaurd'
import { SubmitNewTaskItemFileAction } from '../../../actionCreators/submit/SubmitNewTaskItemFileAction'
import { UploadReducerBase } from 'kat/lib/state/reducers/types/UploadReducerBase'
import { getStorage, ref } from 'firebase/storage'
import { UploadCustomMetaData } from '../../../functions/types/UploadCustomMetaData'
import { UploadMetadata } from 'firebase/storage'
import { ClearTaskItemFileAction } from '../../../actionCreators/ClearTaskItemFileAction'

const description = 'submitNewTaskItemFileFlow'
export const submitNewTaskItemFileFlow = function* () {
  while (1) {
    const action: SubmitNewTaskItemFileAction =
      yield takeGaurd<SubmitNewTaskItemFileAction>(
        'SUBMIT_NEW_TASK_ITEM_FILE_ACTION'
      )
    const { taskItemId } = action
    const state: StateReducers = yield select(selectState)
    const files = state.filesToUpload?.newTaskItem
    const taskId = getCurrentTaskId({ state })
    const workspaceId = state.currentWorkspaceId || ''

    if (files && workspaceId && taskId && taskItemId && files.length > 0) {
      const uploadsMutable: UploadReducerBase = {}

      files.forEach((file) => {
        const uploadId = createId()
        const storageRef = ref(getStorage())
        const fileName = uploadId

        const originalPath = getTaskFileOriginalPathV2({
          workspaceId,
          fileName,
        })

        const uploadRef = ref(storageRef, originalPath)
        const uploadMeta: UploadCustomMetaData = {
          taskId,
          workspaceId,
          taskItemId,
          originalName: file.name,
        }

        uploadsMutable[uploadId] = {
          data: file,
          fileType: file.type,
          fileSizeBytes: file.size,
          fileName: file.name,
          ref: uploadRef,
          meta: {
            customMetadata: uploadMeta as UploadMetadata['customMetadata'], // need as because FB declration is missing null
          },
          status: 'uploading',
        }
      })

      yield put<UploadRequestAction>({
        type: 'UPLOAD_REQUEST',
        description,
        requestValues: uploadsMutable,
      })

      yield put<ClearTaskItemFileAction>({
        type: 'CLEAR_TASK_ITEM_FILE_ACTION',
        description,
      })
    }
  }
}
