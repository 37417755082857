import { SetSessionStorageAction } from './../actionCreators/SetSessionStorageAction'
import { StateReducers } from './../state/reducers/types/StateReducers'
import { takeGaurd } from 'kat/lib/utilities/takeGaurd'
import { put, select } from 'redux-saga/effects'
import { selectState } from 'kat/lib/state/selectState'
import { ClearTagFilterAction } from '../actionCreators/ClearTagFilterAction'

export const clearTagFilterFlow = function* () {
  while (1) {
    yield takeGaurd<ClearTagFilterAction>('CLEAR_TAG_FILTER_ACTION')
    const state: StateReducers = yield select(selectState)
    const taskFilters = state.sessionStorage?.taskFilters

    yield put<SetSessionStorageAction>({
      type: 'SET_SESSION_STORAGE',
      description: 'FilterByButtonMapDispatch',
      sessionStorage: {
        taskFilters: {
          ...taskFilters,
          tagIds: null,
          unread: null,
        },
      },
    })
  }
}
