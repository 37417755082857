import { updateTaskItemMessageClient } from './../../../functions/utilities/ref/update/updateTaskItemMessageClient'
import { createErrorAction } from 'kat/lib/actionCreators/createErrorAction'
import { PatchMessagesSuccessAction } from './../../../actionCreators/patch/PatchMessages/types/PatchMessagesSuccessAction'
import { put } from 'redux-saga/effects'
import { clientServerTimestamp } from 'kat/lib/sdks/firebase/clientServerTimestamp'
import { getObjectFirstItem } from 'kat/lib/utilities/get/getObjectFirstItem'
import { MessagesReducer } from 'state/reducers/types/MessagesReducer'
import { PatchMessagesRequestAction } from '../../../actionCreators/patch/PatchMessages/types/PatchMessagesRequestAction'
import { getFirestore } from 'firebase/firestore'

interface Props {
  items: MessagesReducer
}

export const patchMessagesSaga = function* ({ items }: Props) {
  yield put<PatchMessagesRequestAction>({
    type: 'PATCH_MESSAGES_REQUEST_ACTION',
    description: 'patchMessagesSaga',
    requestValues: {
      items,
    },
  })

  const { key: messageId, item: firstMessage } = getObjectFirstItem(items)

  if (messageId && firstMessage) {
    try {
      const messageMutable = { ...firstMessage.data }
      const fs = getFirestore()
      messageMutable.edited = clientServerTimestamp()

      const ref = messageMutable.taskItemId
      updateTaskItemMessageClient({
        firestore: fs,
        workspaceId: firstMessage.workspaceId,
        messageId,
        message: messageMutable,
      })

      yield ref

      yield put<PatchMessagesSuccessAction>({
        type: 'PATCH_MESSAGES_SUCCESS_ACTION',
        description: 'patchMessagesSaga',
        requestValues: { items },
        successValues: { items },
      })
    } catch (error) {
      yield put(createErrorAction({ error, description: 'patchMessagesSaga' }))
    }
  } else {
    yield put(
      createErrorAction({ description: 'unexpected: patchMessagesSaga' })
    )
  }
}
