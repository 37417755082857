import { SetNewCalendarItemsAction } from 'actionCreators/set/SetNewCalendarItemsAction'
import { CalendarListReducer } from './types/CalendarListReducer'
import { SetCalendarListAction } from 'actionCreators/set/SetCalendarListAction'

export const calendarListReducer = (
  state?: CalendarListReducer | null,
  action?: SetCalendarListAction | SetNewCalendarItemsAction
): CalendarListReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case 'SET_CALENDAR_LIST_ACTION': {
      return {
        ...state,
        ...action.values,
      }
    }

    case 'SET_NEW_CALENDAR_ITEMS_ACTION': {
      return {
        ...state,
        newlyCreatedCalendarItemIds: action.calendarItemIds,
      }
    }

    default:
      return state || null
  }
}
