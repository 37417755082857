import { LayoutTypesExtended } from './types/LayoutTypesExtra'

export const appRoutes = {
  accountSettingsRoute: {
    pathname: '/account-settings',
    useLayout: 'one' as LayoutTypesExtended,
  },
  calendarRoute: {
    pathname: '/calendar',
    useLayout: 'dashboard' as LayoutTypesExtended,
  },
  searchRoute: {
    pathname: '/search',
    useLayout: 'dashboard' as LayoutTypesExtended,
  },
  moodRoute: {
    pathname: '/mood',
    useLayout: 'dashboard' as LayoutTypesExtended,
  },
  workspaceHomeRoute: {
    pathname: '/workspace/home',
    useLayout: 'dashboard' as LayoutTypesExtended,
  },
  postLoginHomeRoute: {
    pathname: '/dashboard',
    useLayout: 'one' as LayoutTypesExtended,
  },
  loginRoute: {
    pathname: '/login',
    isPreLogin: true,
    useLayout: 'one' as LayoutTypesExtended,
  },
  rootRoute: {
    pathname: '/',
    isPreLogin: true,
  },
  createUserRoute: {
    pathname: '/create-account',
    isPreLogin: true,
    useLayout: 'one' as LayoutTypesExtended,
  },
  confirmEmailRoute: {
    pathname: '/confirm-email',
    isPreLogin: true,
    useLayout: 'minimal' as LayoutTypesExtended,
  },
  serverErrorTestRoute: {
    pathname: '/server-error-test',
    isPreLogin: true,
    useLayout: 'one' as LayoutTypesExtended,
  },
  jsErrorTestRoute: {
    pathname: '/js-error-test',
    isPreLogin: true,
    useLayout: 'one' as LayoutTypesExtended,
  },
  networkErrorTestRoute: {
    pathname: '/network-error-test',
    isPreLogin: true,
    useLayout: 'one' as LayoutTypesExtended,
  },
  resetPasswordRoute: {
    pathname: '/reset-password',
    isPreLogin: true,
    useLayout: 'one' as LayoutTypesExtended,
  },
  updateAvatarRoute: {
    pathname: '/update-avatar',
    useLayout: 'one' as LayoutTypesExtended,
  },
  workspaceSettingsRoute: {
    pathname: '/workspace-settings',
    useLayout: 'one' as LayoutTypesExtended,
  },
  proCanceledRoute: {
    pathname: '/pro-canceled',
    useLayout: 'one' as LayoutTypesExtended,
  },
  updateWorkspaceLogoRoute: {
    pathname: '/update-workspace-logo',
    useLayout: 'one' as LayoutTypesExtended,
  },
  updateWorkspaceNameRoute: {
    pathname: '/update-workspace-name',
    useLayout: 'one' as LayoutTypesExtended,
  },
  paymentFailedRoute: {
    pathname: '/payment-failed',
    useLayout: 'one' as LayoutTypesExtended,
  },
  updateDisplayNameRoute: {
    pathname: '/update-display-name',
    useLayout: 'one' as LayoutTypesExtended,
  },
  updatePasswordRoute: {
    pathname: '/update-password',
    useLayout: 'one' as LayoutTypesExtended,
  },
  notificationSettingsRoute: {
    pathname: '/notification-settings',
    useLayout: 'one' as LayoutTypesExtended,
  },
  darkModeRoute: {
    pathname: '/dark-mode',
    useLayout: 'one' as LayoutTypesExtended,
  },
  billingRoute: {
    pathname: '/billing',
    useLayout: 'one' as LayoutTypesExtended,
  },
  upgradeWorkspaceRoute: {
    pathname: '/upgrade',
    useLayout: 'one' as LayoutTypesExtended,
  },
  inviteDetailRoute: {
    pathname: '/invite-detail',
    useLayout: 'one' as LayoutTypesExtended,
  },
  memberDetailRoute: {
    pathname: '/member-detail',
    useLayout: 'one' as LayoutTypesExtended,
  },
  acceptInviteRoute: {
    pathname: '/accept-invitation',
    useLayout: 'minimal' as LayoutTypesExtended,
  },
  selectWorkspaceRoute: {
    pathname: '/select-workspace',
    useLayout: 'minimal' as LayoutTypesExtended,
  },
  emailLoginRoute: {
    pathname: '/email-login',
    useLayout: 'one' as LayoutTypesExtended,
    isPreLogin: true,
  },
  deleteUserAccountRoute: {
    pathname: '/delete-user-account',
    useLayout: 'one' as LayoutTypesExtended,
  },
  deleteWorkspaceRoute: {
    pathname: '/delete-workspace',
    useLayout: 'one' as LayoutTypesExtended,
  },
  leaveWorkspaceRoute: {
    pathname: '/leave-workspace',
    useLayout: 'one' as LayoutTypesExtended,
  },
  membersRoute: {
    pathname: '/membersMain',
    useLayout: 'one' as LayoutTypesExtended,
  },
  partnersNameRoute: {
    pathname: '/partners-name',
    useLayout: 'minimal' as LayoutTypesExtended,
  },
  creatingFirstWorkspaceRoute: {
    pathname: '/creating-first-workspace',
    useLayout: 'minimal' as LayoutTypesExtended,
  },
  welcomeStepRoute: {
    pathname: '/welcome',
    useLayout: 'minimal' as LayoutTypesExtended,
  },
  welcomeInvitedRoute: {
    pathname: '/welcome-invited',
    useLayout: 'minimal' as LayoutTypesExtended,
  },
  activityNotificationRoute: {
    pathname: '/activity-notification',
    useLayout: 'one' as LayoutTypesExtended,
  },
  addNewLabelRoute: {
    pathname: '/new-label',
    useLayout: 'one' as LayoutTypesExtended,
  },
  editLabelRoute: {
    pathname: '/edit-label',
    useLayout: 'one' as LayoutTypesExtended,
  },
  adminRoute: {
    pathname: '/admin-home',
  },
  adminLoginRoute: {
    pathname: '/admin-login',
  },
  adminExploreRoute: {
    pathname: '/admin-explore',
  },
  adminWorkspaceRoute: {
    pathname: '/admin-workspace',
  },
  adminExportRoute: {
    pathname: '/admin-export',
  },
  labelsRoute: {
    pathname: '/labels',
    useLayout: 'one' as LayoutTypesExtended,
  },
  reportRoute: {
    pathname: '/report',
    useLayout: 'one' as LayoutTypesExtended,
  },
  taskMainRoute: {
    pathname: '/taskDetail',
    useLayout: 'task' as LayoutTypesExtended,
  },
  taskJournalRoute: {
    pathname: '/taskJournal',
    useLayout: 'task' as LayoutTypesExtended,
  },
  taskReminderRoute: {
    pathname: '/taskReminders',
    useLayout: 'task' as LayoutTypesExtended,
  },
  printTaskMainRoute: {
    pathname: '/printTaskDetail',
    useLayout: 'print' as LayoutTypesExtended,
  },
  activitiesRoute: {
    pathname: '/activities',
    useLayout: 'dashboard' as LayoutTypesExtended,
  },
  levelRoute: {
    pathname: '/level',
    useLayout: 'dashboard' as LayoutTypesExtended,
  },
  welcomeToProRoute: {
    pathname: '/welcomeToPro',
    useLayout: 'one' as LayoutTypesExtended,
  },
  workspaceFilesRoute: {
    pathname: '/workspaceFiles',
    useLayout: 'one' as LayoutTypesExtended,
  },
  workspaceSubscriptionRoute: {
    pathname: '/workspaceSubscription',
    useLayout: 'one' as LayoutTypesExtended,
  },
  archiveRoute: {
    pathname: '/archive',
    useLayout: 'dashboard' as LayoutTypesExtended,
  },
}
