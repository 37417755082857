import { GetTasksSuccessAction } from '../../actionCreators/tasks/GetTasksSuccessAction'
import { PatchTasksRequestAction } from '../../actionCreators/tasks/PatchTasksRequestAction'
import { forEach } from 'lodash'
import { createGenericReducer } from 'kat/lib/state/reducers/createGenericReducer'
import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'
import { TasksReducer } from './types/TasksReducer'
import { ClearWorkspaceWatchedTasksAction } from 'actionCreators/tasks/ClearWorkspaceWatchedTasksAction'
import { Task } from '../../functions/types/Task'
import { PostTasksRequestAction } from '../../actionCreators/tasks/PostTasksRequestAction'
import { DeleteTasksRequestAction } from '../../actionCreators/tasks/DeleteTasksRequestAction'
import { DeleteTasksSuccessAction } from '../../actionCreators/tasks/DeleteTasksSuccessAction'

const extend = (
  state?: TasksReducer,
  action?: ClearWorkspaceWatchedTasksAction
): TasksReducer | null => {
  if (!action || !state) {
    return state || null
  }

  switch (action.type) {
    case 'CLEAR_WORKSPACE_WATCHED_TASKS': {
      const newStateMutable: TasksReducer = {}

      forEach(state, (task, id) => {
        if (task.workspaceWatchedType !== action.workspaceWatchedType) {
          newStateMutable[id] = task
        }
      })

      return newStateMutable
    }

    default:
      return state || null
  }
}

const postRequestAction: PostTasksRequestAction['type'] =
  'POST_TASKS_REQUEST_ACTION'

const patchRequestAction: PatchTasksRequestAction['type'] =
  'PATCH_TASKS_REQUEST_ACTION'

const deleteRequestAction: DeleteTasksRequestAction['type'] =
  'DELETE_TASKS_REQUEST_ACTION'

const deleteSuccessAction: DeleteTasksSuccessAction['type'] =
  'DELETE_TASKS_SUCCESS_ACTION'

const clearWorkspaceScopeAction: ClearWorkspaceScopeAction['type'] =
  'CLEAR_WORKSPACE_SCOPE'

const getTasksSuccess: GetTasksSuccessAction['type'] =
  'GET_TASKS_SUCCESS_ACTION'

export const tasksReducer = createGenericReducer<Task, TasksReducer>({
  getSuccessAction: getTasksSuccess,
  postRequestAction,
  deleteRequestAction,
  deleteSuccessAction, // TODO: add delete success because another user may have done it.
  patchRequestAction,
  clearActions: [clearWorkspaceScopeAction],
  extend,
  // debug: true,
})
