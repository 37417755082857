import { createGenericReducer } from 'kat/lib/state/reducers/createGenericReducer'
import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'
import { CalendarEventsReducer } from './types/CalendarEventsReducer'
import { GetCalendarEventsSuccessAction } from 'actionCreators/calendarEvent/GetCalendarEventsSuccessAction'
// import { DeleteCalendarEventsRequestAction } from 'actionCreators/calendarEvent/DeleteCalendarEventsRequestAction'
// import { DeleteCalendarEventsSuccessAction } from 'actionCreators/calendarEvent/DeleteCalendarEventsSuccessAction'
import { CalendarEventModel } from 'functions/types/CalendarEventModel'
import { ClearCalendarEventsAction } from 'actionCreators/calendarEvent/ClearCalendarEventsAction'
import { DeleteCalendarEventsRequestAction } from 'actionCreators/calendarEvent/DeleteCalendarEventsRequestAction'
import { DeleteCalendarEventsSuccessAction } from 'actionCreators/calendarEvent/DeleteCalendarEventsSuccessAction'

const deleteRequestAction: DeleteCalendarEventsRequestAction['type'] =
  'DELETE_CALENDAR_EVENTS_REQUEST_ACTION'

const deleteSuccessAction: DeleteCalendarEventsSuccessAction['type'] =
  'DELETE_CALENDAR_EVENTS_SUCCESS_ACTION'

const clearWorkspaceScopeAction: ClearWorkspaceScopeAction['type'] =
  'CLEAR_WORKSPACE_SCOPE'

const getCalendarEventsSuccess: GetCalendarEventsSuccessAction['type'] =
  'GET_CALENDAR_EVENTS_SUCCESS_ACTION'

const clearCalendarEventsAction: ClearCalendarEventsAction['type'] =
  'CLEAR_CALENDAR_EVENTS_ACTION'

export const calendarEventsReducer = createGenericReducer<
  CalendarEventModel,
  CalendarEventsReducer
>({
  getSuccessAction: getCalendarEventsSuccess,
  deleteRequestAction,
  deleteSuccessAction,
  clearActions: [clearWorkspaceScopeAction, clearCalendarEventsAction],
  // extend,
  // debug: true,
})
