import { createErrorAction } from 'kat/lib/actionCreators/createErrorAction';
import { SubmitUpdateMessageAction } from '../../../actionCreators/submit/SubmitUpdateMessageAction'
import { put, select, spawn } from 'redux-saga/effects'
import { patchMessagesSaga } from 'flows/sagas/patch/patchMessagesSaga'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'kat/lib/state/selectState'
import { MessagesReducer } from 'state/reducers/types/MessagesReducer'
import { takeGaurd } from 'kat/lib/utilities/takeGaurd'

const description = 'submitUpdateMessageFlow'

export const submitUpdateMessageFlow = function* () {
  while (1) {
    const action: SubmitUpdateMessageAction =
      yield takeGaurd<SubmitUpdateMessageAction>('SUBMIT_UPDATE_MESSAGE_ACTION')
    const messageId = action.messageId
    const state: StateReducers = yield select(selectState)
    const query = state.currentLocation?.query
    const taskId = query?.taskId
    const workspaceId = query?.workspaceId

    if (taskId && workspaceId) {
      const items: MessagesReducer = {
        [messageId]: {
          data: action.message,
          taskId,
          workspaceId,
        },
      }

      yield spawn(patchMessagesSaga, {
        items,
      })
    } else {
      yield put(createErrorAction({ description }))
    }
  }
}
