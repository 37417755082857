import { WorkspacesReducer } from './types/WorkspacesReducer'
import { IncrementTaskPageAction } from 'actionCreators/types/IncrementTaskPageAction'
import { SetReachMaxTaskPageAction } from '../../actionCreators/types/SetReachMaxTaskPageAction'
import { workspaceReducerBase } from 'kat/lib/state/reducers/workspaceReducerBase'

export const workspacesReducers = (
  state?: WorkspacesReducer,
  action?: SetReachMaxTaskPageAction | IncrementTaskPageAction
): WorkspacesReducer | null => {
  if (!action) {
    return state || null
  }

  return workspaceReducerBase(state, action)
}
