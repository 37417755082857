import { clientMeta } from 'kat/lib/utilities/clientMeta'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { StateReducers } from 'state/reducers/types/StateReducers'

const isNative =
  clientMeta.platform === 'AndroidNative' || clientMeta.platform === 'IosNative'

export const useSetAppUiState = () => {
  const [isNativeReady, setIsNativeReady] = useState(false)
  const [uiState, setUiState] = useState<AppUiState>()

  const hasWorkspace = useSelector((state: StateReducers) => {
    return !!state.currentWorkspace
  })

  const hasUser = useSelector((state: StateReducers) => {
    return !!state.currentUser
  })

  useEffect(() => {
    if (isNative && !isNativeReady && !window.onStoreReady) {
      const intervalRef = setInterval(() => {
        if (window.setAppUiState) {
          setIsNativeReady(true)
        }
      }, 100)

      return () => {
        clearInterval(intervalRef)
      }
    }

    return
  }, [isNativeReady])

  useEffect(() => {
    if (uiState) {
      window.setAppUiState?.(uiState)
    }
  }, [uiState])

  useEffect(() => {
    if (isNativeReady) {
      if (hasWorkspace) {
        setUiState('workspaceLoaded')
        return
      }
      if (hasUser) {
        setUiState('userLoaded')
        return
      }
      setUiState('storeReady')
    }
  }, [uiState, hasWorkspace, hasUser, isNativeReady])

  return {}
}
