import { addTaskItemBelowFlow } from './addTaskItemBelowFlow'
import { goFowardFlow } from './goFowardFlow'
import { clearTagFilterFlow } from './clearTagFilterFlow'
import { all, spawn, call } from 'redux-saga/effects'
import { submitFlows } from './submit/submitFlows'
import { clearWorkspaceScopeF } from './clearWorkspaceScopeF'
import { flowsBase } from 'kat/lib/flows/flowsBase'
import { saveTaskItemsFlow } from './saveTaskItemsFlow'
import { goBackFlow } from './goBackFlow'

const base = flowsBase()

export const flows = function* root() {
  const allFlows = [
    ...base,
    ...submitFlows,
    addTaskItemBelowFlow,
    saveTaskItemsFlow,
    // waitToJoinWorkspaceF,
    clearWorkspaceScopeF,
    clearTagFilterFlow,
    goFowardFlow,
    goBackFlow,
  ]

  yield all(
    allFlows.map((flow) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(flow)
            break
          } catch (e) {
            console.log(e)
          }
        }
      })
    )
  )
}
