import { ClearTaskItemFileAction } from './../../actionCreators/ClearTaskItemFileAction'
import { FilesToUploadReducer } from './types/FilesToUploadReducer'
import { SubmitNewMessageFilesAction } from '../../actionCreators/submit/SubmitNewMessageFilesAction'
import { SubmitNewMessageDoneAction } from '../../actionCreators/submit/SubmitNewMessageDoneAction'
import { SubmitDeleteNewMessageFileAction } from '../../actionCreators/submit/SubmitDeleteNewMessageFileAction'
import { SubmitNewTaskItemFilesAction } from '../../actionCreators/submit/SubmitNewTaskItemFilesAction'
import { SubmitDeleteNewTaskItemFileAction } from '../../actionCreators/submit/SubmitDeleteNewTaskItemFileAction'

export const filesToUploadReducer = (
  state?: FilesToUploadReducer,
  action?:
    | SubmitNewMessageDoneAction
    | SubmitNewMessageFilesAction
    | SubmitDeleteNewMessageFileAction
    | SubmitNewTaskItemFilesAction
    | SubmitDeleteNewTaskItemFileAction
    | ClearTaskItemFileAction
): FilesToUploadReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case 'SUBMIT_NEW_MESSAGE_DONE_ACTION':
      return {
        ...state,
        newMessages: undefined,
      }

    case 'CLEAR_TASK_ITEM_FILE_ACTION':
      return {
        ...state,
        newTaskItem: undefined,
      }

    case 'SUBMIT_NEW_TASK_ITEM_FILES_ACTION':
      return {
        ...state,
        newTaskItem: action.values.files,
      }

    case 'SUBMIT_NEW_MESSAGE_FILES_ACTION':
      const { files: newMessageFiles } = action.values

      return {
        ...state,
        newMessages: newMessageFiles,
      }

    case 'SUBMIT_DELETE_NEW_MESSAGE_FILE_ACTION':
      const deleteNewMessageFilesAction = action
      const newMessagesUploadFiles = state?.newMessages
      const index = deleteNewMessageFilesAction.values.index || 0
      const newUploadFiles = newMessagesUploadFiles?.splice(index, 0)

      return {
        ...state,
        newMessages: newUploadFiles,
      }

    case 'SUBMIT_DELETE_NEW_TASK_ITEM_FILE_ACTION':
      const newTaskItemUploadFileIndex = action.values.index || 0
      const newFiles =
        state?.newTaskItem?.filter((_, index) => {
          return index !== newTaskItemUploadFileIndex
        }) || []
      return {
        ...state,
        newTaskItem: newFiles,
      }

    default:
      return state || null
  }
}
